import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";


const DropdownLanguage = () => {
 

  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState("en");
 
  const handleLangChange = (evt) => {
    const lang = evt.target.value;
    console.log(lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <select
        className="lang_select"
        onChange={handleLangChange}
        value={language}
      >
        <option value="en">English </option>
        <option value="ar">Arabic </option>
        <option value="ta">Tamil</option>
      </select>
    </>
  );
};

export default DropdownLanguage;
