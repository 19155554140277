import React, { Suspense } from "react";
import Header from "../component/Header";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  HashRouter,
} from "react-router-dom";
import UserProfile from "../component/UserProfile";
// import Announcement from "../pages/announcement/Announcement";

const ExplorePage = React.lazy(() =>
  import("../pages/explorePage/Explorepage")
);
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Elearning = React.lazy(() => import("../pages/eLearning/Elearning"));
const ElearningList = React.lazy(() =>
  import("../pages/eLearning/ElearningList")
);
const ElearningTextPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningTextPage")
);
const ElearningGoogleDrivePage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningGoogleDrivePage")
);
const ElearningPdfPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningPdfPage")
);
const ElearningPptPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningPptPage")
);
const ElearningVideoPage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningVideoPage")
);
const ElearningYoutubePage = React.lazy(() =>
  import("../pages/eLearning/eLearningFiles/ElearningYoutubePage")
);
const YourActivities = React.lazy(() =>
  import("../pages/yourActivities/YourActivities")
);
const Teachers = React.lazy(() => import("../pages/teachers/Teachers"));
const CourseContent = React.lazy(() => import("../pages/CourseContent"));
const ExploreCourse = React.lazy(() =>
  import("../pages/explorePage/ExploreCourse")
);
const ExploreCourseDetails = React.lazy(() =>
  import("../pages/explorePage/ExploreCourseDetails")
);
const Assignments = React.lazy(() =>
  import("../pages/assignments/Assignments")
);
const Assessments = React.lazy(() =>
  import("../pages/assessments/Assessments")
);
const AssignmentStart = React.lazy(() =>
  import("../pages/assignments/AssignmentStart")
);
const AssessmentInstructions = React.lazy(() =>
  import("../pages/assessments/AssessmentInstructions")
);
const AssessmentStart = React.lazy(() =>
  import("../pages/assessments/AssessmentStart")
);
const TeachersDetails = React.lazy(() =>
  import("../pages/teachers/TeachersDetails")
);
const AttendanceFullTimeline = React.lazy(() =>
  import("../pages/attedance/AttendanceFullTimeline")
);
const CalendarPage = React.lazy(() => import("../pages/calendar/Calendar"));
const MyCart = React.lazy(() => import("../pages/myCart/MyCart"));
const MyWallet = React.lazy(() => import("../pages/myWallet/MyWallet"));
const MyBalance = React.lazy(() => import("../pages/myWallet/MyBalance"));
const MyStatements = React.lazy(() => import("../pages/myWallet/MyStatements"));
const MyWalletNotifications = React.lazy(() =>
  import("../pages/myWallet/MyWalletNotifications")
);
const MyNotification = React.lazy(() => import("../pages/MyNotification"));
const NotificationSettings = React.lazy(() =>
  import("../pages/NotificationSettings")
);
const OpenChat = React.lazy(() => import("../pages/OpenChat"));
const MyCourses = React.lazy(() => import("../pages/MyCourses"));
const Attendance = React.lazy(() => import("../pages/attedance/Attendance"));
const ApplyLeave = React.lazy(() => import("../pages/myLeaves/ApplyLeave"));
const ViewLeave = React.lazy(() => import("../pages/myLeaves/ViewLeave"));
const SubjectSyllabus = React.lazy(() => import("../pages/SubjectSyllabus"));

const WalletPaytmPayment = React.lazy(() =>
  import("../pages/myWallet/PaytmPayment")
);
const WalletRazorpayPayment = React.lazy(() =>
  import("../pages/myWallet/RazorpayPayment")
);
const PrintReceipt = React.lazy(() => import("../pages/myWallet/PrintReceipt"));
const WalletPayubizPayment = React.lazy(() =>
  import("../pages/myWallet/PayubizPayment")
);
const WalletPayubizSuccess = React.lazy(() =>
  import("../pages/myWallet/PayubizSuccess")
);
const WalletPayubizFailed = React.lazy(() =>
  import("../pages/myWallet/PayubizFailed")
);
const WalletPayumoneyPayment = React.lazy(() =>
  import("../pages/myWallet/PayumoneyPayment")
);
const WalletPayfortPayment = React.lazy(() =>
  import("../pages/myWallet/PayfortPayment")
);

// Announcement
const Announcement = React.lazy(() =>
  import("../pages/announcement/Announcement")
);
const ViewAnnouncement = React.lazy(() =>
  import("../pages/announcement/ViewAnnouncement")
);

// Chat
const Chat = React.lazy(() => import("../pages/Chat/Chat"));


// VideoConference
const VideoConference = React.lazy(() => import("../pages/VideoConference/VideoConference"));


// CashlessWalletCashlessWallet
const CashlessWallet = React.lazy(() => import("../pages/CashlessWallet/CashlessWallet"));

// Library
const Library = React.lazy(() => import("../pages/Library"));

// ScheduledPrograms
const ScheduledPrograms = React.lazy(() => import("../pages/ScheduledProgram/ScheduledPrograms"));
const ScheduledProgramList = React.lazy(() => import("../pages/ScheduledProgram/ScheduledProgramList"));
const VideoPlaylist = React.lazy(() => import("../pages/ScheduledProgram/VideoPlaylist"));
// Forum
const Forum = React.lazy(() => import("../pages/Forum/Forum"));
const ForumView = React.lazy(() => import("../pages/Forum/ForumView"));

// BusTracking
const BusTracking = React.lazy(() => import("../pages/BusTracking/BusTracking"));
const TripDetails = React.lazy(() => import("../pages/BusTracking/TripDetails"));
const BusRoute = React.lazy(() => import("../pages/BusTracking/BusRoute"));
const AuthorisedPerson = React.lazy(() => import("../pages/BusTracking/AuthorisedPerson"));
const RenewTransportation = React.lazy(() => import("../pages/BusTracking/RenewTransportation"));
const BusFare = React.lazy(() => import("../pages/BusTracking/BusFare"));
// MyDiary
const MyDiary = React.lazy(() => import("../pages/MyDiary/MyDiary"));
const MyDiary2 = React.lazy(() => import("../pages/MyDiary/MyDiary2"));
const NoteView = React.lazy(() => import("../pages/MyDiary/NoteView"));

const Layout = () => {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="*" element={<Dashboard />} />
          <Route exact path="/student" element={<Dashboard />} />
          <Route exact path="/student/dashboard" element={<Dashboard />} />
          <Route exact path="/student/explore" element={<ExplorePage />} />
          <Route exact path="/explore-courses" element={<ExploreCourse />} />
          <Route exact path="/UserProfile" element={<UserProfile />} />
          <Route
            path="/explore-course-details"
            element={<ExploreCourseDetails />}
          />
          <Route path="/e-learning" element={<Elearning />} />
          <Route path="/e-learning-list" element={<ElearningList />} />
          <Route path="/e-learning-text" element={<ElearningTextPage />} />
          <Route path="/e-learning-video" element={<ElearningVideoPage />} />
          <Route path="/e-learning-pdf" element={<ElearningPdfPage />} />
          <Route path="/e-learning-ppt" element={<ElearningPptPage />} />
          <Route
            path="/e-learning-youtube"
            element={<ElearningYoutubePage />}
          />
          <Route
            path="/e-learning-googledrive"
            element={<ElearningGoogleDrivePage />}
          />
          <Route path="/your-activities" element={<YourActivities />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route
            path="/teachers-details/:teacher_id"
            element={<TeachersDetails t={t} />}
          />
          <Route path="/course-content" element={<CourseContent />} />
          <Route path="/assignments" element={<Assignments />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route
            path="/assignment-start/:assignment_id"
            element={<AssignmentStart />}
          />
          <Route
            path="/assessment-instructions/:assessment_id"
            element={<AssessmentInstructions t={t} />}
          />
          <Route
            path="/assessment-start/:assessment_id"
            element={<AssessmentStart t={t} />}
          />
          <Route
            path="/attendance-timeline"
            element={<AttendanceFullTimeline t={t} />}
          />
          <Route path="/calendar" element={<CalendarPage />} />

          {/* Announcement */}
          <Route path="/Announcement" element={<Announcement />} />
          <Route path="/ViewAnnouncement" element={<ViewAnnouncement />} />

          {/* Chat */}
          <Route path="/Chat" element={<Chat />} />


          {/* VideoConference */}
          <Route path="/VideoConference" element={<VideoConference />} />

          {/* CashlessWallet */}
          <Route path="/CashlessWallet" element={<CashlessWallet />} />
          
          {/* Library */}
          <Route path="/Library" element={<Library />} />
          
          {/* ScheduledProgram */}
          <Route path="/ScheduledPrograms" element={<ScheduledPrograms />} />
          <Route path="/ScheduledProgramList" element={<ScheduledProgramList />} />
          <Route path="/VideoPlaylist" element={<VideoPlaylist />} />
         
          {/* Forum */}
          <Route path="/Forum" element={<Forum />} />
          <Route path="/ForumView" element={<ForumView />} />
          
          {/* BusTracking */}
          <Route path="/BusTracking" element={<BusTracking />} />
          <Route path="/TripDetails" element={<TripDetails />} />
          <Route path="/BusRoute" element={<BusRoute />} />
          <Route path="/AuthorisedPerson" element={<AuthorisedPerson />} />
          <Route path="/RenewTransportation" element={<RenewTransportation />} />
          <Route path="/BusFare" element={<BusFare />} />
          
          {/* BusTracking */}
          <Route path="/MyDiary" element={<MyDiary />} />
          <Route path="/MyDiary2" element={<MyDiary2 />} />
          <Route path="/NoteView" element={<NoteView />} />

          <Route path="/my-cart" element={<MyCart />} />
          <Route path="/my-wallet" element={<MyWallet />} />
          <Route path="/my-balance" element={<MyBalance />} />
          <Route path="/my-statements" element={<MyStatements />} />
          <Route
            path="/my-wallet-notifications"
            element={<MyWalletNotifications />}
          />
          <Route path="/notification" element={<MyNotification />} />
          <Route
            path="/notification-settings"
            element={<NotificationSettings />}
          />
          <Route path="/chat" element={<OpenChat />} />
          <Route path="/my-courses" element={<MyCourses />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/apply-leave" element={<ApplyLeave />} />
          <Route path="/view-leave" element={<ViewLeave />} />
          <Route
            path={"/subject-syllabus/:subject_id"}
            element={<SubjectSyllabus />}
          />
          <Route
            path="/wallet/paytm-payment"
            element={<WalletPaytmPayment />}
          />
          <Route
            path="/wallet/razorpay-payment"
            element={<WalletRazorpayPayment />}
          />
          <Route path="/wallet/print-receipt" element={<PrintReceipt />} />
          <Route
            path="/wallet/payubiz-payment"
            element={<WalletPayubizPayment />}
          />
          <Route
            path="/wallet/payubiz-success"
            element={<WalletPayubizSuccess />}
          />
          <Route
            path="/wallet/payubiz-failed"
            element={<WalletPayubizFailed />}
          />
          <Route
            path="/wallet/payment-success"
            element={<WalletPayubizSuccess />}
          />
          <Route
            path="/wallet/payment-failed"
            element={<WalletPayubizFailed />}
          />
          <Route
            path="/wallet/payumoney-payment"
            element={<WalletPayumoneyPayment />}
          />
          <Route
            path="/wallet/payfort-payment"
            element={<WalletPayfortPayment />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Layout;
