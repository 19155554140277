//const api_key = "8b9db89daa4111a468e0cc7dfde4e9dc";
//const student_id = 140;

import AppStrings from "../constants/AppStrings";

const customer_id = 1005;
const api_key = "f565eb30659274280721f9de19ade1de";
const student_id = 62;
const is_logged_in = true;

// const customer_id = JSON.parse(localStorage.getItem(AppStrings.CUSTOMER_ID));
// const api_key = JSON.parse(localStorage.getItem(AppStrings.API_KEY));
// const student_id = JSON.parse(localStorage.getItem(AppStrings.STUDENT_ID));
// const is_logged_in = JSON.parse(localStorage.getItem("is_logged_in"));

const Params = {
  customer_id: customer_id,
  api_key: api_key,
  student_id: student_id,
  is_logged_in: is_logged_in,
};

export default Params;
