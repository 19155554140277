import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ApiConfig from '../api/ApiConfig'
import { simplePostCall } from '../api/ApiServices'
import SpinnerCmp from './SpinnerCmp'
import Params from '../config/Params'

function UserProfile() {
  const { t } = useTranslation()

  const [profileDataState, setProfileDataState] = useState({
    isloading: true,
    profileData: [],
    base_url: '',
  })
  console.log('profileDataState', profileDataState.class_name)

  function getUserProfileData() {
    setProfileDataState({ ...profileDataState, isloading: true })
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      student_id: Params.student_id,
    })

    simplePostCall(ApiConfig.USERT_PROFILE, newRequestBody)
      .then((data) => {
        if (data.result) {
          setProfileDataState(data.message)
        } else {
          setProfileDataState({ ...profileDataState, isloading: false })
        }
      })
      .catch((error) => {
        setProfileDataState({ ...profileDataState, isloading: false })
        console.log('api response', error)
      })
  }

  useEffect(() => {
    document.title = 'Edsys | User Profile '
    getUserProfileData()
  }, [])
  return (
    <>
      {profileDataState.isloading ? (
        <SpinnerCmp />
      ) : (
        <>
          {/* <!-- Container-fluid starts--> */}
          <div className="page-wrapper compact-wrapper" id="pageWrapper">
            <div className="page-body-wrapper sidebar-icon">
              <div className="page-body" id="container">
                <div className="dashboard-default-sec explore-details">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mt-3">
                        <div className="card-header pb-0">
                          <h5 className="m-b-0">
                            <div className="my-profile-menu d-flex">
                              <ul
                                className="nav nav-pills flex-grow-1 py-2"
                                id="pills-icontab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="pills-iconhome-tab"
                                    data-bs-toggle="pill"
                                    href="#pills-iconhome"
                                    role="tab"
                                    aria-controls="pills-iconhome"
                                    aria-selected="true"
                                  >
                                    <i className="icofont icofont-user-male"></i>
                                    {t('Student Details')}
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="pills-iconcontact-tab"
                                    data-bs-toggle="pill"
                                    href="#pills-iconcontact"
                                    role="tab"
                                    aria-controls="pills-iconcontact"
                                    aria-selected="false"
                                  >
                                    <i className="icofont icofont-settings"></i>
                                    {t('Father Details')}
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="pills-iconcontact-tab"
                                    data-bs-toggle="pill"
                                    href="#pills-iconcontact-1"
                                    role="tab"
                                    aria-controls="pills-iconcontact-1"
                                    aria-selected="false"
                                  >
                                    <i className="icofont icofont-settings"></i>
                                    {t('Mother Details')}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </h5>
                        </div>

                        <div className="tab-content" id="pills-icontabContent">
                          <div
                            className="tab-pane fade active show"
                            id="pills-iconhome"
                            role="tabpanel"
                            aria-labelledby="pills-iconhome-tab"
                          >
                            <div className="card mt-3">
                              <div className="card-header pb-0">
                                <h5 className="m-b-0">
                                  <i className="icofont icofont-user-male mr-3"></i>{' '}
                                  {t('Personal Details')}
                                </h5>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive info-table">
                                    
                                  <table className="table text-nowrap">
                                    <tbody>
                                    <tr>
                                        <td>{t('Name')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Admission Number')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_admission_num}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>{t('Grade Number')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_class}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td >
                                          <h6>{t('Division Name')}</h6>
                                        </td>:
                                          {profileDataState.student_division}

                                      </tr>
                                      <tr>
                                        <td>{t('Address')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_address}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>{t('Mobile Number')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_father_mobile}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Email ID')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_email}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('School Hours')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.school_hour}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Student Landmark')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_landmark ?profileDataState.student_landmark :"NA"}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Disability')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_disability ? profileDataState.student_disability : 'NA'}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Blood Group')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_blood_group}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Date Of Birth')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {/* {profileDataState.profileData.staff_id} */}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Gender')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_gender}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Student Nationlity')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_nationality}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Status')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_status}
                                        </td>
                                      </tr> 
                                      <tr>
                                        <td>{t('Student User ID')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_user_id}
                                        </td>
                                      </tr> <tr>
                                        <td>{t('Mifare Card ID')}.:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_mifare_card_id}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pills-iconcontact"
                            role="tabpanel"
                            aria-labelledby="pills-iconcontact-tab"
                          >
                            
                            <div className="card mt-2">
                            <div className="card-header pb-0">
                                <h5 className="m-b-0">
                                  <i className="icofont icofont-user-male mr-3"></i>{' '}
                                  {t('Father Details')}
                                </h5>
                              </div>
                              <div className="mt-2 contact-form card-body">
                              <div className="table-responsive info-table">
                                  <table className="table text-nowrap">
                                    <tbody>
                                      <tr>
                                        <td >
                                          <h6>{t('Father Name')}:</h6>
                                        </td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_father_name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Father Email')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_father_email}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>{t('Father Phone')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_father_mobile}
                                        </td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                                </div>
                               
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-iconcontact-1"
                            role="tabpanel"
                            aria-labelledby="pills-iconcontact-tab-1"
                          >
                            <div className="card mt-2">
                            <div className="card-header pb-0">
                                <h5 className="m-b-0">
                                  <i className="icofont icofont-user-male mr-3"></i>{' '}
                                  {t('Mother Details')}
                                </h5>
                              </div>
                              <div className="mt-2 contact-form card-body">
                              <div className="table-responsive info-table">
                                  <table className="table text-nowrap">
                                    <tbody>
                                      <tr>
                                        <td >
                                          {t('Mother Name')}:
                                        </td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_mother_name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Mother Email')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_mother_email}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>{t('Mother Phone')}:</td>
                                        <td className="font-medium text-dark-medium">
                                          {profileDataState.student_mother_mobile ? profileDataState.student_mother_mobile :"NA"}
                                        </td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </>
      )}
    </>
  )
}
export default UserProfile
