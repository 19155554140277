//const BASE_URL = 'https://edusuite.trackschoolbus.com/';
//const BASE_URL_LOGIN = 'https://app.klickitapp.com/';
//const BASE_URL ='https://app.klickitapp.com/';
//const BASE_URL ='https://app.edsys.in/';
const BASE_URL = "https://stage.edsys.in/";
const PATH = "api/studentapp/";
const NEW_BASE_URL = "https://stage.edsys.in:3014/api/student/";
const USER_BASE_URL = 'https://stage.edsys.in:3014/api/'

const ApiConfig = {
  BASE_URL: BASE_URL,
  //login url
  LOGIN_API: BASE_URL + PATH + "login",

  OTP_LOGIN_API: BASE_URL + PATH + "login/otp_login/",
  // OTP_LOGIN_API:'http://carrental.vehicletracking.qa/api/vendor/login',
  VERIFY_OTP_API: BASE_URL + PATH + "login/verifyotp",
  SOCIAL_LOGIN: BASE_URL + PATH + "login/social_login",
  //base url
  ATTENDANCE_BY_DATE_API: BASE_URL + PATH + "attendancebydate",
USERT_PROFILE:NEW_BASE_URL + 'profile',
  DASHBOARD_API: BASE_URL + PATH + "dashboard",

  SYLLABUS_API: BASE_URL + PATH + "syllabus",

  TEACHER_LIST_API: BASE_URL + PATH + "teacher/list",
  TEACHER_DETAILS_API: BASE_URL + PATH + "teacher/view",

  ACTIVITY_COUNT_API: BASE_URL + PATH + "activitycount",

  TODAYS_ASSESSMENT_LIST_API: NEW_BASE_URL +  "assessment/today",
  MONTHLY_LESSON_LIST_API: BASE_URL + PATH + "syllabus/lesson",
  UPCOMING_ASSESSMENT_LIST_API: NEW_BASE_URL +  "assessment/upcoming",
  ASSESSMENT_HISTORY_LIST_API: NEW_BASE_URL + "assessment/history",
  ASSESSMENT_DETAILS_API: BASE_URL + PATH + "assessment/details",
  ASSESSMENT_ALL_DETAILS_API: BASE_URL + PATH + "assessment/alldetails",
  ASSESSMENT_SUBMIT_API: BASE_URL + PATH + "assessment/submit",

  ASSIGNMENT_HISTORY_LIST_API: NEW_BASE_URL + "assignment/history",
  TODAYS_ASSIGNMENT_LIST_API: NEW_BASE_URL + "assignment/pending",
  ASSIGNMENT_UPCOMING_API: NEW_BASE_URL + "assignment/upcoming",

  // ASSIGNMENT_HISTORY_LIST_API: BASE_URL + PATH + "assignment/list/history",
  // TODAYS_ASSIGNMENT_LIST_API: BASE_URL + PATH + "assignment/list/today",
  // ASSIGNMENT_UPCOMING_API: BASE_URL + PATH + "assignment/list/upcoming",
  ASSIGNMENT_SAVE: BASE_URL + PATH + "assignment/assignments/save/",
  ASSIGNMENT_SUBMISSION: BASE_URL + PATH + "assignment/assignments/submit",
  ASSIGNMENT_DETAILS: BASE_URL + PATH + "assignment/assignments/details",

  ELEARNING_SUBJECT: BASE_URL + PATH + "edirectory/subjects",
  ELEARNING_LIST: BASE_URL + PATH + "edirectory/list",
  ELEARNING_PDF_DETAILS: BASE_URL + PATH + "edirectory/details",
  PROFILE: BASE_URL + PATH + "profile",

  DAILY_TIMETABLE: BASE_URL + PATH + "timetable/daily",
  WEEKLY_TIMETABLE: BASE_URL + PATH + "timetable/weekly",
  MONTHLY_TIMETABLE: BASE_URL + PATH + "timetable/monthly",

  PROFILE_EDIT: BASE_URL + PATH + "/profile/editprofilepic",

  UPDATE_PROFILE_URL: BASE_URL + PATH + "profile/editprofilepic?",
  ASSESSMENT_RESULT_API:
    BASE_URL + PATH + "assessment/resultdownload/answersheet?",

  SUBJECT_LESSON_API: BASE_URL + PATH + "subject/lessons",
  GET_ATTENDANCE_BY_DATE_API: BASE_URL + PATH + "dashboard/getattendancebydate",
  WALLET_BALANCE_API: BASE_URL + PATH + "wallet/balance",
  WALLET_STATEMENT_API: BASE_URL + PATH + "wallet/statement",
  WALLET_NOTIFICATION_API: BASE_URL + PATH + "wallet/notification",
  WALLET_ADD_MONEY_API: BASE_URL + PATH + "wallet/addmoney",
  WALLET_SAVE_TRANSACTION_API: BASE_URL + PATH + "wallet/savetransaction",
  BYPASS_LOGIN_API: BASE_URL + PATH + "reactlogin/bypass",
};

export default ApiConfig; //{ ApiConfig, BASE_URL, BASE_URL_LOGIN };
